<template>
  <section class="app-container app-flex-col-container createCoordPicPage" v-loading="loading" id="knifeProCode">
    <div class="content-wrapper">
      <div class="title">{{ isLack ? '创建' : '更新' }}号码图</div>
      <div class="header">
        <div class="slider-wrapper">
          <el-slider v-model="gridSizeOfCm" :min="2" :step="1" :max="30"> </el-slider>
          <div class="label">{{ gridSizeOfCm }}cm/格</div>
        </div>
        <div class="info">
          <span class="label"> 宽： </span>
          <span class="value">{{ this.width }}px</span>
          <span class="label"> 高： </span>
          <span class="value">{{ this.height }}px</span>
        </div>

        <Popover @sureHandler="clipCoordPic($event)">
          <template #tip>
            <p>坐标图已经存在，确定要更新吗？此操作不能撤销！</p>
          </template>
          <template #reference="{ scope: loading }">
            <el-button
              class="update-btn"
              :loading="loading"
              size="mini"
              type="danger"
              v-show="isLack === false || isFresh === true"
              >更新坐标图</el-button
            >
          </template>
        </Popover>
        <el-button
          size="mini"
          type="primary"
          v-if="isLack === true && isFresh === false"
          :loading="loading"
          class="label"
          @click="clipCoordPic($event)"
          >生成坐标图</el-button
        >
      </div>
    </div>
    <div class="canvas-wrapper flex-col-content" element-loading-text="加载中..." v-loading="isUpdateLoading">
      <div class="wrapper">
        <canvas class="canvas" id="canvas" ref="canvas" :width="width" :height="height"></canvas>
      </div>
    </div>
  </section>
</template>

<script>
import { createGripKnife, changeGripKnife } from '@/api/product/protoApi'
import { getListByProtoId as list } from '@/api/product/protoStructApi'

import { base64ToFile } from '@/utils'

import createCheckCanvasMixin from '../mixins/createCheckCanvasMixin'

export default {
  name: 'createCoordPic',
  mixins: [createCheckCanvasMixin],
  data() {
    return {
      gridSizeOfCm: 2,
      structs: [],
      isFresh: undefined,
      referenceId: this.$route.query.id
    }
  },

  computed: {
    isLack() {
      const isLack = !this.structs.every(({ showImageGroupList }) => {
        return showImageGroupList[0] && showImageGroupList[0].coordinateImagePath
      })
      return isLack
    }
  },
  methods: {
    async init() {
      const id = this.$route.query.protoId
      if (!id) {
        return this.$router.back()
      }
      //获取参考尺码数据
      this.loading = true
      try {
        const detail = await this.baseDetail()
        if (detail) {
          this.initData(detail)
          await this.list()
          this.updateGripCanvas()
          this.watchGridSizeOfCm()
        }
      } catch {}
      this.loading = false
    },

    createCoordFile(cloneCanvas) {
      const base64 = cloneCanvas.toDataURL()
      console.log('base64', base64)
      const coordFile = base64ToFile(base64, 'coordinateImagePath.png')
      return coordFile
    },

    async clipCoordPic($el) {
      $el && $el.$refs && $el.$refs.popover.doClose()
      this.loading = true
      try {
        const { gridsClipPics, cloneCanvas } = this.createGridsClipPics()
        const { structId, id: sizeId, protoId } = this.$route.query
        let api = createGripKnife

        // const coordFile = base64ToFile(this.canvas.toDataURL(), 'coordinateImagePath.jpg')
        const coordFile = this.createCoordFile(cloneCanvas)

        const uploadedOssPicObj = await $uploadOSSPics([
          {
            files: [coordFile],
            prop: 'coordinateImagePath',
            dirPrefix: $ossDirMapWithType['2'],
            uuidSuffix: `_${this.gridSizeOfCm}`,
            uuidPrefix: this.$route.query.protoId
          }
        ])
        if (!uploadedOssPicObj) {
          this.loading = false
          return
        }
        const params = {
          coordinateImagePath: uploadedOssPicObj.coordinateImagePath,
          coordinateImagePathList: gridsClipPics,
          styleIdList: structId && structId.split(','),
          sizeId: Number(sizeId),
          prototypeId: Number(protoId),
          gridSize: Number(this.gridSizeOfCm)
        }
        if (this.isLack === false) {
          console.log('dsadsa', this.structs)
          params.groupIdList = this.structs.map(({ showImageGroupList = [] }) => {
            return showImageGroupList[0] && showImageGroupList[0].id
          })
          delete params.styleIdList
          api = changeGripKnife
        }
        const { code } = await api(params)
        if ($SUC({ code })) {
          if (this.isLack) {
            this.isFresh = true
          }
          this.$message.success('提交成功')
          this.$emit('createCoordPicSuccess')
          this.$router.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },

    async list() {
      try {
        const { code, detail = [] } =
          (await list({
            id: this.$route.query.id
          })) || {}
        if ($SUC({ code })) {
          this.structs = detail.styleList
          let coordinateKnifeLayoutImagePath = ''
          try {
            if (detail.styleList[0].showImageGroupList[0].coordinateKnifeLayoutImagePath) {
              coordinateKnifeLayoutImagePath = detail.styleList[0].showImageGroupList[0].coordinateKnifeLayoutImagePath
            }
          } catch (err) {
            // console.log(err)
          }

          this.isFresh = false
          this.gridSizeOfCm = coordinateKnifeLayoutImagePath ? this.getResetGridSize(coordinateKnifeLayoutImagePath) : 2
          if (isNaN(this.gridSizeOfCm)) {
            this.gridSizeOfCm = 2
          }
        }
      } catch (err) {
        console.log(err)
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.createCoordPicPage.createCoordPicPage {
  width: 100%;
  padding-right: 40px;
  .content-wrapper {
    padding: 15px;
    background: #fff;
  }
  .title {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid $border-color;
  }
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
  }
  .update-btn.update-btn.update-btn,
  .el-button {
    height: 38px;
    line-height: 38px !important;
    height: 38px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 20px;
  }
  .slider-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 0 20px;
    width: 300px;
    border-radius: 2px;
    background: #eee;
    .el-slider {
      flex: 1;
    }
    .label {
      margin-left: 10px;
      font-size: 16px;
      white-space: nowrap;
      color: $color-primary;
    }
  }
  .info {
    display: inline-block;
    width: 300px;
    padding: 10px 20px;
    background: #eee;

    .value {
      margin-right: 20px;
    }
  }
  .canvas-wrapper {
    overflow: hidden;
    padding: 10px;
    .wrapper {
      width: 100%;

      height: 100%;
      overflow: auto;
      border: 1px solid $color-primary;
      .canvas {
        box-shadow: 0 0 5px 1px #666;
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>
